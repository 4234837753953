<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title>
            Trial Room
            <v-spacer></v-spacer>
            <v-flex xs12 sm2 pt-7 pl-3>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From Date"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm2 pt-7 pl-3>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To Date"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm2 pl-3>
              <v-select
                v-model="shop"
                :items="shops"
                item-text="organization"
                item-value="_id"
                dense
                outlined
                clearable
                color="#B1B1B1"
                hide-details=""
                placeholder="Shops"
                label="Shops"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm2 pl-3>
              <v-select
                v-model="status"
                :items="statusArray"
                dense
                outlined
                clearable
                color="#B1B1B1"
                hide-details=""
                placeholder="Status"
                label="Status"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm2 pl-3>
              <v-select
                v-model="shipStatus"
                :items="shippingStatusArray"
                dense
                outlined
                clearable
                color="#B1B1B1"
                item-text="text"
                item-value="value"
                hide-details=""
                placeholder="Shipping Status"
                label="Shipping Status"
              ></v-select>
            </v-flex> </v-card-title
          ><br />
          <!-- <b-container v-if="report"> -->
          <div v-if="user">
            <v-data-table
              :headers="headers"
              :items="user"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:[`item.date`]="{ item }">
                <span>{{ item.createdDate.slice(0, 10) }}</span>
              </template>
              <template v-slot:[`item.product`]="{ item }">
                <v-img
                  width="80px"
                  height="80px"
                  contain
                  :src="mediaURL + item.product.photos[0]"
                >
                </v-img>
              </template>
              <template v-slot:[`item.productname`]="{ item }">
                <span>{{ item.product.productname }}</span>
              </template>
              <template v-slot:[`item.store`]="{ item }">
                <span>{{ item.store.organization }}</span>
              </template>
              <template v-slot:[`item.user`]="{ item }">
                <span>{{ item.user.firstname }} {{ item.user.lastname }}</span>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-flex
                  xs12
                  pt-5
                  v-if="
                    item.shippingStatus == 'Pending' ||
                    item.shippingStatus == 'Confirmed' ||
                    item.shippingStatus == 'Packed' ||
                    item.shippingStatus == 'Shipped'
                  "
                >
                  <v-select
                    v-model="item.shippingStatus"
                    :items="verifyStatus"
                    item-text="name"
                    item-value="value"
                    item-color="#8d8d8d"
                    color="#8d8d8d"
                    outlined
                    dense
                    label="Change Status"
                    @change="askStatus(item._id, item.shippingStatus)"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 v-else>
                  <span>{{ item.shippingStatus }}</span>
                </v-flex>
              </template>
              <template v-slot:[`item._id`]="{ item }">
                <v-icon small class="mr-2" @click="userinfo(item._id)">
                  mdi-eye
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-card>
        <div class="pt-2" v-if="pages > 1">
          <v-pagination
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#FF0000"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="updateDialog" max-width="600px">
      <v-card>
        <v-card-title style="font-size: 16px"
          >Are you sure you want to change Status?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="updateDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="updateStatus(dialogId, dialogStatus)"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      user: [],
      photos: [],
      seller: [],
      subcat: [],
      productStatus: false,
      product: [],
      productArray: [],
      categoryArray: [],
      msg: null,
      widthOfCard: "250px",
      appLoading: false,
      dialog: false,
      userStatus: null,
      userRole: localStorage.getItem("role"),
      tab: null,
      text: "",
      reviewDialog: false,
      approveDialog: false,
      rejectDialog: false,
      reason: "",
      docs: [],
      doc: null,
      documents: "",
      docDialog: false,
      navItems: [
        { name: "Products", type: null },
        { name: "Offers", type: "Offer" },
        { name: "Deals", type: "Deals" },
      ],
      headers: [
        { text: "Date", value: "date", width: "100px" },
        { text: "Photo", value: "product", width: "50px" },
        { text: "Product Name", value: "productname", width: "450px" },
        { text: "Store", value: "store", width: "150px" },
        { text: "User", value: "user", width: "150px" },
        { text: "Status", value: "status", width: "200px" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      verifyStatus: ["Pending", "Confirmed", "Packed", "Shipped"],
      shop: "",
      shops: [],
      statusArray: [
        {
          value: "Pending",
          text: "Pending",
        },
        {
          value: "Active",
          text: "Active",
        },
        {
          value: "Cancelled",
          text: "Cancelled",
        },
      ],
      status: "",
      shippingStatus: "",
      updateDialog: false,
      dialogId: null,
      dialogStatus: null,
      shippingStatusArray: [
        {
          value: "Pending",
          text: "Pending",
        },
        {
          value: "Confirmed",
          text: "Confirmed",
        },
        {
          value: "Packed",
          text: "Packed",
        },
        {
          value: "Shipped",
          text: "Shipped",
        },
        {
          value: "ReachedStore",
          text: "Reached Store",
        },
        {
          value: "ReadyForTrial",
          text: "Ready for Trial",
        },
        {
          value: "Return",
          text: "Return",
        },
        {
          value: "ReturnConfirmed",
          text: "Return Confirmed",
        },
      ],
      shipStatus: "",
      currentType: null,
      fromDate: "",
      toDate:"",
      menu: false,
      menu1: false,
      currentPage: 1,
      pages: 0,
      count: 20,
      totalData: 0,
      totalRows: 0,
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
    status() {
      this.getData();
    },
    shipStatus() {
      this.getData();
    },
    fromDate() {
      this.getData();
    },
    toDate() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.getShop();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/trial/admin/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          status: this.status,
          shipStatus: this.shipStatus,
          fromDate: this.fromDate,
          toDate: this.toDate,
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.user = response.data.data;
            this.totalData = response.data.count;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          // this.ServerError = true;
          console.log(err);
        });
    },
    getShop() {
      axios({
        method: "GET",
        url: "/user/allSellers",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.shops = response.data.data;
            this.pages = response.data.pages;
            this.totalData = response.data.count;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    askStatus(id, status) {
      this.updateDialog = true;
      this.dialogId = id;
      this.dialogStatus = status;
    },
    updateStatus(id, status) {
      // console.log(id,"dfgkm",status)
      // alert(status);
      this.updateDialog = false;
      axios({
        url: "/trial/admin/status/update",
        method: "POST",
        data: { id: id, status: status },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.appLoading = false;
            this.dialogId = null;
            this.dialogStatus = null;
            this.msg = "Status updated successfully";
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
    userinfo(uid) {
      this.$router.push("/trialRoomDetails?id=" + uid);
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 14px;
}

.item_value {
  font-size: 13px;
}
</style>
  